<template>
	<MobileAppWizardStep
		v-model="application"
		:steps="steps"
		:step-name="stepName"
		:start-with-last="startWithLast"
		@previous="$emit('previous')"
		@next="$emit('next')"
		@unvalidated="$emit('unvalidated')"
		@validated="$emit('validated')"
	/>
</template>

<script>
export default {
	name: 'MobileAppStepGooglePlayStore',
	components: {
		MobileAppWizardStep: () => ({
			component: import('@/components/SuperAdmin/MobileApp/CreationSteps/MobileAppWizardStep')
		})
	},
	props: {
		value: {
			required: true,
			type: Object
		},
		startWithLast: {
			required: false,
			type: Boolean,
			default: false
		},
		stepName: {
			required: true,
			type: String
		}
	},
	data: function () {
		return {
			steps: [
				{
					title: this.$t('mobileapp.creationSteps.android.steps.1.title'),
					href: () => 'https://play.google.com/console/u/0/developers'
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.2.title')
				},
				{
					// eslint-disable-next-line sonarjs/no-duplicate-string
					title: this.$t('mobileapp.creationSteps.android.steps.3.title'),
					subtitle: this.$t('mobileapp.creationSteps.android.steps.3.steps.1.title'),
					valueToCopy: () => this.application.title
				},
				{
					// eslint-disable-next-line sonarjs/no-duplicate-string
					title: this.$t('mobileapp.creationSteps.android.steps.3.title'),
					subtitle: this.$t('mobileapp.creationSteps.android.steps.3.steps.2.title')
				},
				{
					// eslint-disable-next-line sonarjs/no-duplicate-string
					title: this.$t('mobileapp.creationSteps.android.steps.3.title'),
					subtitle: this.$t('mobileapp.creationSteps.android.steps.3.steps.3.title')
				},
				{
					// eslint-disable-next-line sonarjs/no-duplicate-string
					title: this.$t('mobileapp.creationSteps.android.steps.3.title'),
					subtitle: this.$t('mobileapp.creationSteps.android.steps.3.steps.4.title')
				},
				{
					// eslint-disable-next-line sonarjs/no-duplicate-string
					title: this.$t('mobileapp.creationSteps.android.steps.3.title'),
					subtitle: this.$t('mobileapp.creationSteps.android.steps.3.steps.5.title')
				},
				{
					// eslint-disable-next-line sonarjs/no-duplicate-string
					title: this.$t('mobileapp.creationSteps.android.steps.3.title'),
					subtitle: this.$t('mobileapp.creationSteps.android.steps.3.steps.6.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.3.title'),
					subtitle: this.$t('mobileapp.creationSteps.android.steps.3.steps.7.title')
				},
				{
					// eslint-disable-next-line sonarjs/no-duplicate-string
					title: this.$t('mobileapp.creationSteps.android.steps.4.title'),
					subtitle: this.$t('mobileapp.creationSteps.android.steps.4.steps.1.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.2.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.2.steps.1.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.2.steps.2.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.2.steps.3.title'),
					valueToCopy: () => 'Identifiants de test'
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.2.steps.4.title', { login: 'login@welyb.fr' }),
					valueToCopy: () => 'login@welyb.fr'
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.2.steps.5.title', { password: 'password' }),
					valueToCopy: () => 'password'
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.2.steps.6.title'),
					valueToCopy: () => "Compte de test permettant d'effectuer une revue de validation / Test account enabling review validation"
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.2.steps.7.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.2.steps.8.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.2.steps.9.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.3.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.3.steps.1.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.3.steps.2.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.3.steps.3.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.4.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.4.steps.1.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.4.steps.2.title', { email: 'admin@welyb.fr' }),
					valueToCopy: () => 'admin@welyb.fr'
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.4.steps.3.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.4.steps.4.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.4.steps.5.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.4.steps.6.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.4.steps.7.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.4.steps.8.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.4.steps.9.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.4.steps.10.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.4.steps.11.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.4.steps.12.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.4.steps.13.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.4.steps.14.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.4.steps.15.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.4.steps.16.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.4.steps.17.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.4.steps.18.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.5.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.5.steps.1.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.5.steps.2.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.5.steps.3.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.5.steps.4.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.5.steps.5.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.6.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.6.steps.1.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.6.steps.2.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.6.steps.3.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.7.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.7.steps.1.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.7.steps.2.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.7.steps.3.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.7.steps.3.steps.1.title'),
					valueToCopy: () => 'finance'
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.7.steps.3.steps.2.title'),
					valueToCopy: () => 'pro'
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.7.steps.3.steps.3.title'),
					valueToCopy: () => 'Outils'
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.7.steps.3.steps.4.title')
				},
				{
					// eslint-disable-next-line sonarjs/no-duplicate-string
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.7.steps.4.title'),
					subtitle: this.$t('mobileapp.creationSteps.android.steps.4.steps.7.steps.4.steps.1.title', { email: 'support@welyb.fr' }),
					valueToCopy: () => 'support@welyb.fr'
				},
				{
					// eslint-disable-next-line sonarjs/no-duplicate-string
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.7.steps.4.title'),
					subtitle: this.$t('mobileapp.creationSteps.android.steps.4.steps.7.steps.4.steps.2.title')
				},
				{
					// eslint-disable-next-line sonarjs/no-duplicate-string
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.7.steps.4.title'),
					subtitle: this.$t('mobileapp.creationSteps.android.steps.4.steps.7.steps.4.steps.3.title'),
					valueToCopy: () => this.application.marketing_url
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.7.steps.5.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.7.steps.6.title')
				},
				{
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.8.title')
				},
				{
					// eslint-disable-next-line sonarjs/no-duplicate-string
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.9.title'),
					subtitle: this.$t('mobileapp.creationSteps.android.steps.4.steps.9.steps.1.title'),
					valueToCopy: () => this.application.title
				},
				{
					// eslint-disable-next-line sonarjs/no-duplicate-string
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.9.title'),
					subtitle: this.$t('mobileapp.creationSteps.android.steps.4.steps.9.steps.2.title'),
					valueToCopy: () => this.application.promotional_text
				},
				{
					// eslint-disable-next-line sonarjs/no-duplicate-string
					title: this.$t('mobileapp.creationSteps.android.steps.4.steps.9.title'),
					subtitle: this.$t('mobileapp.creationSteps.android.steps.4.steps.9.steps.3.title'),
					valueToCopy: () => this.application.description
				}
			]
		}
	},
	computed: {
		application: {
			get: function () {
				return this.value
			},
			set: function (val) {
				this.$emit('input', val)
			}
		}
	}
}
</script>
